import React from "react";

function Save() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="80"
            fill="none"
            viewBox="0 0 103 80"
        >
            <path
                fill="#B91C45"
                fillRule="evenodd"
                d="M8.993 55.17l16.274-13.084c7.665-5.365 30.275-1.33 41.58.486 5.474.878 6.486 9.952-.976 9.147l-13.713-1.087c-2.984-.301-3.491 2.718.485 3.123 6.035.356 13.079 1.171 19.027.935 16.187-.644 16.757-6.634 24.346-12.433 1.714-1.31 3.911-1.158 5.316.562 1.242 1.522.874 3.533-.401 5.14-3.526 4.462-16.863 20.512-35.705 19.566-9.222-.456-18.438-.897-27.647-1.323-5.002-.421-10.307 2.255-14.704 4.992L8.988 55.16l.005.01zM.273 59.688l5.444-4.735a.796.796 0 011.12.082L23.222 73.95a.797.797 0 01-.079 1.122L17.7 79.806a.796.796 0 01-1.12-.079L.195 60.813a.8.8 0 01.078-1.125zM69.161 0a19.51 19.51 0 0113.81 5.731 19.587 19.587 0 015.719 13.836c0 5.19-2.058 10.166-5.72 13.836a19.51 19.51 0 01-13.809 5.731 19.51 19.51 0 01-13.809-5.731 19.586 19.586 0 01-5.72-13.836c0-5.19 2.058-10.166 5.72-13.836A19.51 19.51 0 0169.162 0zm0 3.142a16.355 16.355 0 0111.584 4.807 16.418 16.418 0 014.799 11.607 16.43 16.43 0 01-4.799 11.607 16.366 16.366 0 01-11.584 4.808c-4.345 0-8.512-1.73-11.584-4.808a16.43 16.43 0 01-3.551-17.888 16.416 16.416 0 013.551-5.326 16.38 16.38 0 0111.584-4.807z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#B91C45"
                fillRule="evenodd"
                d="M69.16 4.984a14.516 14.516 0 0110.284 4.268 14.57 14.57 0 014.258 10.304c0 3.864-1.532 7.57-4.259 10.303a14.53 14.53 0 01-10.282 4.27 14.529 14.529 0 01-10.284-4.269 14.594 14.594 0 010-20.609A14.54 14.54 0 0169.16 4.984zm5.26 16.85a4.323 4.323 0 00-.366-.892 3.054 3.054 0 00-.616-.736 7.439 7.439 0 00-.719-.58 6.62 6.62 0 00-.876-.494 18.28 18.28 0 00-.878-.408c-.217-.09-.543-.211-.942-.366a36.226 36.226 0 01-.813-.318c-.177-.074-.41-.18-.7-.316a4.11 4.11 0 01-.648-.358 5.176 5.176 0 01-.459-.383 1.342 1.342 0 01-.344-.484 1.54 1.54 0 01-.098-.557 1.53 1.53 0 01.697-1.291 3.01 3.01 0 011.796-.498c.335.001.669.046.993.134.295.075.584.176.862.301.235.11.462.234.68.373.212.136.364.239.454.307l.168.138a.357.357 0 00.312.082.308.308 0 00.271-.185l.941-1.696a.337.337 0 00-.06-.443 3.89 3.89 0 00-.173-.163 6.04 6.04 0 00-.453-.337 5.82 5.82 0 00-.735-.435 7.435 7.435 0 00-1.009-.388 6.333 6.333 0 00-1.283-.272V9.523a.362.362 0 00-.103-.272.366.366 0 00-.271-.103H68.48a.357.357 0 00-.271.111.358.358 0 00-.111.272v2.09a5.327 5.327 0 00-2.957 1.557 3.988 3.988 0 00-1.136 2.813c-.002.305.031.61.097.907.061.264.143.522.245.772.105.243.244.47.412.674.158.198.327.387.504.568.19.185.398.35.621.495.255.17.472.307.654.413.236.132.479.251.727.358.301.136.543.24.713.31.171.07.413.166.714.291.416.163.725.288.927.375.201.09.456.215.765.372.244.117.472.264.678.438.152.142.283.306.388.486.114.186.176.4.176.617a1.632 1.632 0 01-.716 1.416 2.796 2.796 0 01-1.651.5c-.289 0-.576-.03-.858-.092a6.058 6.058 0 01-2.818-1.451l-.024-.025a.293.293 0 00-.271-.106.372.372 0 00-.272.142l-1.193 1.568a.37.37 0 00-.081.22.403.403 0 00.105.255c.064.075.132.146.204.212.173.155.354.3.542.435.273.205.56.39.858.557a7.7 7.7 0 001.18.516c.478.172.974.294 1.478.362v2.033a.356.356 0 00.108.271.364.364 0 00.271.112h1.565a.352.352 0 00.272-.106.354.354 0 00.105-.272v-2.033a4.98 4.98 0 002.997-1.587 4.429 4.429 0 001.153-3.06 3.839 3.839 0 00-.149-1.087"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Save;
