import React from "react";

function Productivity() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="62"
            height="80"
            fill="none"
            viewBox="0 0 62 80"
        >
            <path
                fill="#B91C45"
                d="M42.75 67.774a7.136 7.136 0 01-5.055-2.099 7.163 7.163 0 01-2.093-5.066c0-1.9.753-3.721 2.094-5.064a7.141 7.141 0 0110.11 0 7.172 7.172 0 010 10.13 7.143 7.143 0 01-5.056 2.099zm18.432-4.632l-3.007-.979c.05-.512.077-1.033.077-1.56 0-.528-.028-1.046-.077-1.558l3.007-.982a1.055 1.055 0 00.676-1.328l-1.376-4.248a1.06 1.06 0 00-1.328-.678l-3.007.982a15.488 15.488 0 00-1.832-2.523l1.862-2.567a1.063 1.063 0 00-.234-1.475l-3.606-2.625a1.053 1.053 0 00-1.47.234l-1.86 2.564a15.323 15.323 0 00-2.962-.962v-3.17a1.06 1.06 0 00-1.052-1.055h-4.457a1.056 1.056 0 00-1.055 1.054v3.173c-1.017.22-2.01.542-2.962.962l-1.858-2.564a1.06 1.06 0 00-1.471-.234l-3.606 2.626a1.057 1.057 0 00-.232 1.474l1.86 2.567a15.608 15.608 0 00-1.83 2.523l-3.007-.982a1.055 1.055 0 00-1.326.678l-1.379 4.248a1.06 1.06 0 00.677 1.328l3.007.982a15.593 15.593 0 000 3.118l-3.007.98a1.06 1.06 0 00-.677 1.33l1.38 4.248a1.054 1.054 0 001.325.675l3.007-.979c.524.9 1.137 1.745 1.83 2.523l-1.86 2.567a1.06 1.06 0 00.232 1.474l3.606 2.626a1.058 1.058 0 001.471-.235l1.858-2.563c.953.42 1.945.743 2.962.962v3.17A1.062 1.062 0 0040.536 80h4.457a1.056 1.056 0 001.052-1.057v-3.17c1.018-.22 2.01-.541 2.962-.962l1.86 2.563a1.054 1.054 0 001.47.235l3.606-2.626a1.062 1.062 0 00.234-1.474l-1.862-2.567a15.488 15.488 0 001.832-2.523l3.007.98a1.055 1.055 0 001.328-.676l1.377-4.249a1.061 1.061 0 00-.677-1.332z"
            ></path>
            <path
                fill="#B91C45"
                d="M33.218 41.804a2.722 2.722 0 012.775 1.059l1.104 1.521c.235-.082.471-.159.709-.232v-1.886a2.727 2.727 0 01.797-1.922c.51-.51 1.2-.797 1.92-.798h4.457a2.697 2.697 0 012.049.943V20.044H33.218v21.76zM25.606 60.99v-.374-.373l-1.79-.583a2.73 2.73 0 01-1.744-3.428l1.38-4.246a2.711 2.711 0 012.577-1.881c.284 0 .567.045.837.133l1.788.583c.141-.215.287-.407.43-.604l-1.105-1.524a2.727 2.727 0 01.6-3.8l1.915-1.395V27.11H16.681V62.39h5.902c.324-.38.745-.662 1.218-.818l1.79-.58h.015zM.174 62.383h13.813v-27.68H.174v27.68zM39.391 9.77a1.623 1.623 0 00.402 2.252 1.611 1.611 0 002.248-.402l6.313-9.076A1.623 1.623 0 0047.73.16a1.61 1.61 0 00-.907-.147l-11.084 1.44a1.612 1.612 0 00-1.351 1.182 1.62 1.62 0 001.766 2.027l3.331-.43L0 28.12v3.909L41.283 7.047l-1.892 2.72v.002z"
            ></path>
        </svg>
    );
}

export default Productivity;
