function Quite() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <path
                fill="#B91C45"
                d="M33.764 22.477l9.883 11.27V16.213l-9.883 6.264zM12.174 51.87h12.721l18.753 11.887V51.609L23.028 28.1H12.174v23.772zM59.406 19.994l-3.443 3.454a23.239 23.239 0 015.075 7.59 23.275 23.275 0 011.762 8.964 23.25 23.25 0 01-3.242 11.919l3.354 3.824a28.105 28.105 0 004.758-15.727 28.13 28.13 0 00-2.132-10.824 28.086 28.086 0 00-6.129-9.166l-.003-.034zM50.917 28.503l-3.443 3.453a11.282 11.282 0 013.304 8.033c0 .587-.045 1.174-.135 1.754l3.823 4.357a16.298 16.298 0 001.183-6.111 16.151 16.151 0 00-4.748-11.482l.016-.004z"
            ></path>
            <path
                fill="#B91C45"
                d="M19.68 11.522a34.65 34.65 0 0120.243-6.5 34.9 34.9 0 0124.663 10.253 35.037 35.037 0 0110.232 24.71 34.861 34.861 0 01-9.329 23.755L19.681 11.51v.013zm20.26 63.426a34.9 34.9 0 01-24.662-10.252 35.037 35.037 0 01-10.232-24.71 34.92 34.92 0 012.8-13.725 34.86 34.86 0 017.966-11.511l46.003 52.454a34.69 34.69 0 01-21.875 7.744zm36.797-19.385a39.813 39.813 0 003.14-15.561 39.813 39.813 0 00-3.14-15.561 39.91 39.91 0 00-8.558-12.72 39.862 39.862 0 00-12.692-8.576A39.661 39.661 0 0039.94 0a39.638 39.638 0 00-15.547 3.145 39.876 39.876 0 00-12.696 8.575A39.909 39.909 0 003.14 24.44 39.874 39.874 0 000 40.003a39.874 39.874 0 003.14 15.56 39.86 39.86 0 008.558 12.718 39.77 39.77 0 0012.695 8.575A39.584 39.584 0 0039.94 80a39.602 39.602 0 0015.547-3.145 39.753 39.753 0 0012.692-8.576 39.861 39.861 0 008.558-12.716z"
            ></path>
        </svg>
    );
}

export default Quite;
