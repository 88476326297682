import React, { ReactElement } from "react";
import colors from "@styles/variables/officecubiclequotesVariables.module.scss";
import styles from "./styles.module.scss";

import Save from "./svg/Save";
import Productivity from "./svg/Productivity";
import Quite from "./svg/Quite";
import FeatureList from "@components/shared/featureList";
const benefitsList = [
    {
        title: "Focus",
        description:
            "Providing individual workspaces for each employee not only enhances their focus by isolating them from office noise but also contributes to noise reduction through the walls themselves.",
        icon: <Quite />,
    },
    {
        title: "Productivity",
        description:
            "Reduced noise and fewer distractions result in enhanced productivity. By eliminating the need to monitor activities in neighboring cubicles, employees can maintain concentration on their own tasks, ultimately reducing stress levels.",
        icon: <Productivity />,
    },
    {
        title: "Space",
        description:
            "If you require additional private offices but face limitations in terms of space or budget for construction, opting for cost-effective cubicles is an excellent solution. This allows you to enjoy the advantages of individual workspaces without the need for physical wall construction.",
        icon: <Save />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={benefitsList}
            mainTitle="Benefits of office cubicles"
            classes={{
                mainTitleClasses:
                    "text-center text-xl capitalize lg:text-2xl mb-14 mt-14 md:mt-24",
                innerSectionClasses: "lg:flex flex-wrap justify-between",
                oneBoxClasses: `text-center mb-12 lg:mb-20 w-full lg:w-[30%] py-16 ${styles["item"]}`,
                iconClasses: "children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl my-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                mainTitleColor: colors.secondary,
                titleColor: colors.secondary,
                descriptionColor: colors.secondary,
            }}
        />
    );
}
